
import {
  ComputedRef,
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  defineAsyncComponent,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { gpf } from "@/utils/global-functions";
import {
  DataCat,
  MemberCatQuestion,
  PayloadSubmitCat,
} from "@/services/v1/member/cat/types";
import { isMember } from "@/utils/roles";
import { postMemberCatSubmit } from "@/services/v1/member/cat";
import { postFeatureCatSubmit } from "@/services/v1/feature/cat";

const ModalDoneCatTryout = defineAsyncComponent(
  () => import(`./modal-done-cat-tryout.vue`)
);

const CatNavigation = defineAsyncComponent(
  () => import(`./cat-navigation.vue`)
);

export default defineComponent({
  components: {
    ModalDoneCatTryout,
    CatNavigation,
  },
  props: {
    cpnsCode: {
      type: String,
      default: "",
    },
    catCode: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();

    const cpnsCode = computed(() => props.cpnsCode);
    const catCode = computed(() => props.catCode);

    // START --- state data for cat ------------------------------------------------------
    const catQuestionDataKey = `catQuestionData${catCode.value}`;
    const answerCatQuestionKey = `answerCatQuestion${catCode.value}`;
    const dataCat = ref({} as DataCat);
    const allCatQuestion = ref([] as MemberCatQuestion[]);
    const currentQuestionIdx = ref(0 as number);
    const currentSoal: ComputedRef<MemberCatQuestion> = computed(() => {
      if (!allCatQuestion.value.length) return {} as MemberCatQuestion;
      return allCatQuestion.value[currentQuestionIdx.value];
    });
    const time = reactive({
      hours: "00",
      minutes: "00",
      seconds: "00",
    });
    const timeExpired = ref(false as boolean);
    const countDownDate = ref(0 as number);
    // END --- state data for cat --------------------------------------------------------

    // START --- for setup cat -----------------------------------------------------------

    const setupCat = async () => {
      const catQuestionData = window.localStorage.getItem(catQuestionDataKey);
      const answerCatQuestion = window.localStorage.getItem(
        answerCatQuestionKey
      );

      if (catQuestionData) {
        const dataCatFromLocalStorage: DataCat = JSON.parse(
          catQuestionData as string
        );
        dataCat.value = dataCatFromLocalStorage;
        countDownDate.value = new Date(
          dataCatFromLocalStorage.endDate
        ).getTime();
        countTime();

        // fetch answer data when reload page
        const listCatQuestion = dataCatFromLocalStorage.soal;
        const listAnswerCatQuestion = JSON.parse(answerCatQuestion as string)
          ?.result;

        allCatQuestion.value = listCatQuestion.map((question, idx) => {
          return {
            ...question,
            answer: listAnswerCatQuestion[idx]?.opsi,
            ragu: listAnswerCatQuestion[idx]?.ragu === "Y",
          };
        });
      }
    };

    const countTime = () => {
      // Update the count down every 1 second
      const x = setInterval(async () => {
        // Get today's date and time
        const now = new Date().getTime();

        // Find the distance between now and the count down date
        const distance = countDownDate.value - now;

        // Time calculations for days, hours, minutes and seconds
        // const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        time.hours = hours.toString();
        time.minutes = minutes.toString();
        time.seconds = seconds.toString();

        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(x);
          timeExpired.value = true;

          await submitCat();
        }
      }, 1000);
    };

    onMounted(() => {
      gpf.hideModal("#modal-cat-cpns");
      gpf.hideModal("#modal-cat-pppk");
      setupCat();
    });
    // END --- for setup cat -------------------------------------------------------------

    // START --- for handling soal -------------------------------------------------------
    const changeSoalCis = (tp: string): void => {
      tp === "next"
        ? (currentQuestionIdx.value += 1)
        : (currentQuestionIdx.value -= 1);
    };

    const chooseOpt = (cs: any, no: any, idx: any): void => {
      const a = cs.option;
      for (let i = 0; i < a.length; i++) {
        if (i !== idx) {
          cash('[opt="chs-' + no + "-" + i + '"]').removeClass("active");
        } else {
          cash('[opt="chs-' + no + "-" + idx + '"]').addClass("active");
          allCatQuestion.value[currentQuestionIdx.value].answer =
            cs.option[idx].option;
          allCatQuestion.value[currentQuestionIdx.value].answerIdx = idx;
          saveAnswerToLocalStorage();
        }
      }
    };

    const setRagu = () => {
      // setup payload for submit cat
      allCatQuestion.value[currentQuestionIdx.value].ragu = !allCatQuestion
        .value[currentQuestionIdx.value].ragu;
      saveAnswerToLocalStorage();
    };

    const saveAnswerToLocalStorage = () => {
      const result = allCatQuestion.value.map(
        (catQuestion: MemberCatQuestion) => {
          const obj = {
            nomor: catQuestion.nomor,
            idSoal: catQuestion.id,
            opsi: catQuestion.answer,
            ragu: catQuestion.ragu ? "Y" : "N",
          };
          return obj;
        }
      );
      window.localStorage.setItem(
        answerCatQuestionKey,
        JSON.stringify({ result })
      );
    };
    // END --- for handling soal ---------------------------------------------------------

    // START --- for submit cat ----------------------------------------------------------
    const submitCat = async () => {
      gpf.gLoading.show();

      try {
        let res: any = {};
        const resultCat = window.localStorage.getItem(answerCatQuestionKey);
        const parseResultCat = JSON.parse(resultCat as string);

        const payloadSubmitCat = {
          hasil: parseResultCat?.result,
        };

        if (isMember.value) {
          res = await postMemberCatSubmit(
            catCode.value as string,
            payloadSubmitCat as PayloadSubmitCat
          );
        } else {
          res = await postFeatureCatSubmit(
            catCode.value as string,
            payloadSubmitCat as PayloadSubmitCat
          );
        }

        removeModuleDataCatInLocalStorage();

        router.push({
          path: `${cpnsCode.value}/hasil`,
          query: {
            ...route.query,
          },
        });
      } catch (err: any) {
        console.error("err submitCat ", err?.response);
      } finally {
        gpf.gLoading.hide();
      }
    };

    const removeModuleDataCatInLocalStorage = () => {
      window.localStorage.removeItem(catQuestionDataKey);
      window.localStorage.removeItem(answerCatQuestionKey);
    };
    // END --- for submit cat ------------------------------------------------------------

    return {
      gpf,
      dataCat,
      currentSoal,
      allCatQuestion,
      currentQuestionIdx,
      time,
      timeExpired,
      countDownDate,

      changeSoalCis,
      chooseOpt,
      setRagu,

      submitCat,
    };
  },
});
